import { useEffect, useState } from "react";
import "./App.css";
import { request } from './constants/constants';
import { Loader } from "monday-ui-react-core";
import Report from "./Components/Report";
import Swal from 'sweetalert2';

function App() {
  const queryParams = new URLSearchParams(window.location.search)
  const signature = queryParams.get('signature');
  const payload = queryParams.get('payload');
  const ticketId = queryParams.get('ticketId');
  const portalId = queryParams.get('portalId');

  const [inspectionReport, setInspectionReport] = useState({ticketId: null});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    request('GET', `/tickets/inspectionproperties?payload=${payload}&signature=${signature}&ticketId=${ticketId}&portalId=${portalId}`).then(res => {
      setInspectionReport(res.data.inspectionProperties);
      setIsLoading(false);
    }).catch(error => {
      setError(error);
    })
  }, [ticketId, payload, signature, portalId]);

  const submitReport = (inspectionReport) => {

    setIsLoading(true);
    request('POST', `/tickets/inspectionproperties/submit?payload=${payload}&signature=${signature}&ticketId=${ticketId}&portalId=${portalId}`, { inspectionReport, payload, signature, ticketId, portalId }).then(res => {
      setIsLoading(false);
      setInspectionReport(inspectionReport);
      Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
    }).catch(error => {
      setIsLoading(false);
      setInspectionReport(inspectionReport);
      Swal.fire({ title: "Error", text: error.response.data.message, type: "error", timer: 5000 });
    })
  }

  if (error) {
    return (
      <div className="container">
          <div className="row h-100">
              <div className="col-sm-12 my-auto">
                  <div className='mx-auto' style={{height:'50px', width:'200px', paddingTop:'250px'}}>
                      <div className='d-flex justify-content-center' style={{
                          color: "var(--primary-color)"
                      }}>
                          <h6>Something has gone wrong. Please close this page and try again. If it continues to fail, please reach out to support@luxie.tech</h6>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="container">
          <div className="row h-100">
              <div className="col-sm-12 my-auto">
                  <div className='mx-auto' style={{height:'50px', width:'200px', paddingTop:'250px'}}>
                      <div className='d-flex justify-content-center' style={{
                          color: "var(--primary-color)"
                      }}>
                          <Loader size={50} />
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  } else {
    return (
      <Report inspectionProperties={inspectionReport} submitForm={submitReport} />
    );
  }
}

export default App;
