import Axios from 'axios';

// const API_URL = 'https://sanyati.ngrok.io'
const API_URL = 'https://sanyati.happs.luxie.tech'

export const request = (method, url, data = null) => {
    if (data) {
        return Axios.request({
            method: method,
            url: API_URL + url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
    } else {
        return Axios.request({
            method: method,
            url: API_URL + url,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
    }
}

export const getBaseUrl = () => {
    return API_URL;
}