import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import HeaderImg from "../public/images/header.png";
import LogoImg from "../public/images/sanyati-logo.png";
import SideImg from "../public/images/side-long.png";
import SignatureCanvas from 'react-signature-canvas'
import { ButtonGroup } from "monday-ui-react-core";
import Swal from 'sweetalert2';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import './report.css'

const Report = (props) => {
  
    const [inspectionReport, setInspectionReport] = useState({});
    const [toName, setToName] = useState('');
    const contentEl = useRef();
    const clientSignatureClearBtnEl = useRef();
    const managerSignatureClearBtnEl = useRef();
    const submitBtnEl = useRef();
    
    useEffect(() => {
        setInspectionReport(props.inspectionProperties);
      }, [props.inspectionProperties]);

    useEffect(() => {
        setToName(`${props.inspectionProperties.firstname} ${props.inspectionProperties.lastname}`);
    }, [props.inspectionProperties.firstname, props.inspectionProperties.lastname]);



    const [clientSignature, setClientSignature] = useState({});
    const [managerSignature, setManagerSignature] = useState({});


    const clearClientSignature = () => {
        clientSignature.clear();
    }

    const clearManagerSignature = () => {
        managerSignature.clear();
    }

    const submitForm = async () => {

        if (inspectionReport.notes_of_inspection === "" || inspectionReport.re_inspsection_required === null || inspectionReport.re_inspsection_required === "" || clientSignature.isEmpty() || managerSignature.isEmpty() || inspectionReport.date_of_inspection === null || inspectionReport.date_of_inspection === "") {
            Swal.fire({ title: "Warning", text: "Please ensure you complete the notes, re-inspection and the form is signed before submitting", type: "warning", timer: 5000 });
        } else {
            const pdfFile = await generatePdf()

            props.submitForm({
                ...inspectionReport, 
                // date_of_inspection: inspectionReport.date_of_inspection.toISOString().split('T')[0],
                date_of_inspection: inspectionReport.inspectionDate,
                pdfFile: pdfFile
            });
        }
    }

    const onNotesChange = ( e ) => {
        setInspectionReport({...inspectionReport, notes_of_inspection: e.target.value});
    }

    const onToNameChange = ( e ) => {
        setToName(e.target.value);
    }

    const onInspectionRequiredChanage = ( e ) => {
        setInspectionReport({...inspectionReport, re_inspection_required: e});
    }

    const generatePdf = async () => { 
        clientSignatureClearBtnEl.current.style.display = "none"
        managerSignatureClearBtnEl.current.style.display = "none"
        submitBtnEl.current.style.display = "none"

        return html2canvas(contentEl.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF("p", "mm", "a4");
            const width = pdf.internal.pageSize.getWidth()
            const height = pdf.internal.pageSize.getHeight()

            pdf.addImage(imgData, 'JPEG', 0, 0, width, height)

            clientSignatureClearBtnEl.current.style.display = "inline-block"
            managerSignatureClearBtnEl.current.style.display = "inline-block"
            submitBtnEl.current.style.display = "inline-block"

            return btoa(pdf.output())
        })
    }

    const Row = ({children, key}) => {
        return (
            <div key={key} style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                {children}
            </div>
        )
    }

    const Header = ({children}) => {
        return (
            <div style={{paddingLeft: "10px", width : "160px", height: "50px", display: "flex", alignItems: "center", border: "2px solid rgba(0, 0, 0, 0.361)", borderLeft: "none", borderTop: "none"}}>
                <span style={{fontWeight: "bold", fontSize: "16px"}}>{children}</span>
            </div>
        )
    }

    const Item = ({children, width, height = "50px", paddingLeft, key}) => {
        const border = {border: "2px solid rgba(0, 0, 0, 0.361)", borderLeft: "none", borderTop: "none"}
        const style = width ? {
            width : width,
            paddingLeft: paddingLeft ? paddingLeft : "10px",
            height: height,
            ...border
        } : {
            flexGrow : 1,
            paddingLeft: paddingLeft ? paddingLeft : "10px",
            height: height,
            ...border
        }
        return (
            <div key={key} style={style}>
                {children}
            </div>
        )
    }

    return (
        <div id="content" ref={contentEl} style={{width: "100%", maxWidth: "1200px"}}>
            <div style={{display:"flex", position: "relative", width: "100%"}}>
                <div style={{backgroundImage:`url(${SideImg})`, backgroundRepeat: "repeat-y", width: "160px", height: "100%", position: "absolute"}}></div>
                <div style={{backgroundImage:`url(${LogoImg})`, backgroundRepeat: "no-repeat", width: "160px", height: "185px", position: "absolute", backgroundSize: "cover"}}></div>
                
                <div style={{flexGrow: 1}}>
                    <img src={HeaderImg} alt="" style={{width: "100%"}}/>
                    <div style={{paddingRight: "20px", paddingLeft: "175px"}}>
                        <div>
                            <h1 style={{textAlign: "center", fontSize: "28px", fontWeight: "bold", marginTop: "30px"}}>Inspection Report</h1>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", border: "2px solid rgba(0, 0, 0, 0.361)", borderRight: "none", borderBottom: "none"}}>
                                <Row>
                                    <Header>TICKET ID:</Header>
                                    <Item>
                                        <p>{inspectionReport.ticketId}</p>
                                    </Item>
                                </Row>
                                <Row>
                                    <Header>Franchisee:</Header>
                                    <Item>
                                        <p>{inspectionReport.franchise_number} {inspectionReport.franchise_name}</p>
                                    </Item>
                                </Row>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                    <Header>TO:</Header>
                                    
                                    {/* <Item> */}
                                    <div style={{
                                        border: "2px solid rgba(0, 0, 0, 0.361)", borderLeft: "none", borderTop: "none",
                                        height: 50,
                                        paddingLeft: 10,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <input style={{ border: 'none', fontSize: 16, background: 'transparent'}} key="toName" name="toName" type="text" onChange={onToNameChange} value={toName} />
                                    </div>
                                    
                                    {/* </Item> */}
                                        
                                    <Header width="100px">FROM:</Header>
                                    <Item>
                                        <p>{inspectionReport.opsManager}</p>
                                    </Item>
                                </div>
                                <Row>
                                    <Header>EMAIL ADDRESS:</Header>
                                    <Item width="400px">
                                        <p>{inspectionReport.email}</p>
                                    </Item>
                                    <Header width="100px">DATE:</Header>
                                    <Item paddingLeft="10px">
                                        <p>{inspectionReport.inspectionDate}</p>
                                    </Item>
                                </Row>
                                <Row>
                                    <Header>COMPANY:</Header>
                                    <Item>
                                        <p>{inspectionReport.company_name}</p>
                                    </Item>
                                </Row>
                                <Row>
                                    <Header>ADDRESS:</Header>
                                    <Item>
                                        <p>{inspectionReport.address_of_inspection}</p>
                                    </Item>
                                </Row>
                            </div>
                        </div>

                        <div style={{marginTop: "60px"}}>
                            <div style={{border: "2px solid rgba(0, 0, 0, 0.361)"}}>
                                <div style={{height: "50px", paddingLeft: "10px", display: "flex", alignItems: "center", borderBottom: "2px solid rgba(0, 0, 0, 0.361)"}}>
                                    <span style={{fontWeight: "bold", fontSize: "16px"}}>Additional Information / Notes of inspection</span>
                                </div>
                                <div>
                                    <textarea onChange={onNotesChange} style={{width: "100%", maxWidth: "100%", height: "200px", padding: "10px", border: "0", outline: "0", fontSize: "15px"}} name="additional_note" id="form_additional_info" value={inspectionReport.notes_of_inspection}></textarea>
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex"}}>
                                    <span style={{fontWeight: "bold", fontSize: "13px", marginRight: "15px"}}>Re-Inspection required: </span>
                                    <ButtonGroup
                                        onSelect={onInspectionRequiredChanage}
                                        options={[{'text':'Yes', 'value':'Yes'}, {'text':'No','value':'No'}]}
                                        value={inspectionReport.re_inspection_required}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: "30px"}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <span style={{fontWeight: "bold", fontSize: "16px"}}><u>PLEASE SIGN </u></span>
                                <span style={{fontWeight: "bold", fontSize: "16px", marginTop: "10px"}}>Series</span>

                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                                    <span style={{fontSize: "16px", marginTop: "10px"}}>Client Signature:</span>
                                    <div style={{border: "1px solid rgba(0, 0, 0, 0.361)"}}>
                                        <SignatureCanvas penColor='black' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { setClientSignature(ref) }} />
                                        <div>
                                            <button ref={clientSignatureClearBtnEl} onClick={clearClientSignature}> Clear </button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                                    <span style={{fontSize: "16px", marginTop: "10px"}}>Operations Manager Signature:</span>
                                    <div style={{border: "1px solid rgba(0, 0, 0, 0.361)"}}>
                                        <SignatureCanvas penColor='green' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { setManagerSignature(ref) }} />
                                        <div>
                                            <button ref={managerSignatureClearBtnEl} onClick={clearManagerSignature}> Clear </button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width:"100%", textAlign: "right", paddingTop: "30px", paddingBottom: "30px"}}>
                                    <button ref={submitBtnEl} className="button" type="submit" id="submitBtn" onClick={submitForm}>Submit</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Report;
